import React, { useEffect, useState } from 'react';
import { ArrowRight, Calendar, ShoppingBag, BookOpen, Star } from 'lucide-react';
import livewell from '../assets/livewell2.jpg';
import shop from '../assets/shop2.jpg';
import stories from '../assets/stories.png';
import exclusive from '../assets/exclusive22.jpg';
import axios from 'axios';

const YOUTUBE_API_KEY = 'AIzaSyA3zZhJeyXx_WOVBGhXaHkkHHov5qyBiEs';
const SHOPIFY_ACCESS_TOKEN = 'c3dc7231670ccec1aaa223cbfa24d3bd';
const SHOPIFY_STORE_URL = 'https://store.pachila.in/api/2021-07/graphql.json';

const WelcomePage = () => {
    const videoIds = ['5Wex7pPQ3D8', '_wVY9dedK_4', 'R2HtxHppbqU'];
    const [trendingVideos, setTrendingVideos] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchVideoDetails = async () => {
            try {
                const response = await axios.get(
                    `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoIds.join(',')}&key=${YOUTUBE_API_KEY}`
                );
                const videosData = response.data.items.map((item) => ({
                    id: item.id,
                    url: `https://www.youtube.com/watch?v=${item.id}`,
                    thumbnail: item.snippet.thumbnails.high.url,
                    title: item.snippet.title.substring(0, 50) + '...',
                }));
                setTrendingVideos(videosData);
            } catch (error) {
                console.error('Error fetching video details:', error);
            }
        };

        const fetchProducts = async () => {
            const productHandles = [
                "manjaly-banana-powder",
                "manjaly-jack-fruit-seed-powder",
                "black-pepper-powder",
                "manjaly-arrow-root-powder",
                "manjaly-kannankaya-powder",
            ];
        
            try {
                const productsData = [];
        
                for (const handle of productHandles) {
                    const response = await axios.post(
                        SHOPIFY_STORE_URL,
                        {
                            query: `
                                {
                                    productByHandle(handle: "${handle}") {
                                        id
                                        title
                                        handle
                                        images(first: 1) {
                                            edges {
                                                node {
                                                    src
                                                }
                                            }
                                        }
                                        priceRange {
                                            minVariantPrice {
                                                amount
                                            }
                                        }
                                    }
                                }
                            `,
                        },
                        {
                            headers: {
                                'X-Shopify-Storefront-Access-Token': SHOPIFY_ACCESS_TOKEN,
                                'Content-Type': 'application/json',
                            },
                        }
                    );
        
                    const product = response.data.data.productByHandle;
                    if (product) {
                        productsData.push({
                            id: product.id,
                            title: product.title,
                            url: `https://store.pachila.in/products/${product.handle}`,
                            imageUrl: product.images.edges[0]?.node.src || '',
                            price: product.priceRange.minVariantPrice.amount,
                        });
                    }
                }
        
                setProducts(productsData);
            } catch (error) {
                console.error('Error fetching specific products:', error);
            }
        };

        fetchVideoDetails();
        fetchProducts();
    }, []);

    return (
        <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
            {/* Hero Section */}
            <section className="relative overflow-hidden bg-gradient-to-r from-green-50 to-green-100 py-20 sm:py-32">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col items-center text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                            Your Journey to Wellness
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl">
                            Discover natural products and holistic wellness solutions tailored to your needs.
                        </p>
                        <div className="mt-10 flex items-center gap-x-6">
                            <a href="/shop" className="rounded-full bg-green-600 px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 transition-all">
                                Shop Now
                            </a>
                            <a href="/live-well" className="text-sm font-semibold leading-6 text-gray-900 flex items-center">
                                Learn More <ArrowRight className="ml-2 h-4 w-4" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Services Grid */}
            <section className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
                <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">Our Core Services</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="relative h-64 rounded-2xl overflow-hidden group">
                        <img src={shop} alt="Shop" className="w-full h-full object-cover transition-transform group-hover:scale-110" />
                        <div className="absolute inset-0 bg-black bg-opacity-40 flex items-end p-6">
                            <a href="https://store.pachila.in" className="w-full text-center bg-white bg-opacity-90 py-3 rounded-full text-gray-900 font-semibold hover:bg-opacity-100 transition-all">
                                Shop Now
                            </a>
                        </div>
                    </div>
                    <div className="relative h-64 rounded-2xl overflow-hidden group">
                        <img src={livewell} alt="Live Well" className="w-full h-full object-cover transition-transform group-hover:scale-110" />
                        <div className="absolute inset-0 bg-black bg-opacity-40 flex items-end p-6">
                            <a href="/live-well" className="w-full text-center bg-white bg-opacity-90 py-3 rounded-full text-gray-900 font-semibold hover:bg-opacity-100 transition-all">
                                Live Well
                            </a>
                        </div>
                    </div>
                    <div className="relative h-64 rounded-2xl overflow-hidden group">
                        <img src={stories} alt="Stories" className="w-full h-full object-cover transition-transform group-hover:scale-110" />
                        <div className="absolute inset-0 bg-black bg-opacity-40 flex items-end p-6">
                            <a href="/stories" className="w-full text-center bg-white bg-opacity-90 py-3 rounded-full text-gray-900 font-semibold hover:bg-opacity-100 transition-all">
                                Read Stories
                            </a>
                        </div>
                    </div>
                    <div className="relative h-64 rounded-2xl overflow-hidden group">
                        <img src={exclusive} alt="Exclusive" className="w-full h-full object-cover transition-transform group-hover:scale-110" />
                        <div className="absolute inset-0 bg-black bg-opacity-40 flex items-end p-6">
                            <a href="/exclusive" className="w-full text-center bg-white bg-opacity-90 py-3 rounded-full text-gray-900 font-semibold hover:bg-opacity-100 transition-all">
                                Exclusives
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Appointment Banner */}
            <section className="bg-gradient-to-r from-green-600 to-green-700 py-16 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto text-center">
                    <h3 className="text-3xl font-bold text-white mb-8">Ready to Begin Your Wellness Journey?</h3>
                    <a href="/live-well#book-appointment" className="inline-block rounded-full bg-white px-8 py-4 text-sm font-semibold text-green-600 shadow-lg hover:bg-gray-50 transition-all">
                        Book an Appointment
                    </a>
                </div>
            </section>

            {/* Featured Products */}
            <section className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
                <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">Featured Products</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {products.map((product) => (
                        <div key={product.id} className="group relative bg-white rounded-2xl shadow-md overflow-hidden transition-all hover:-translate-y-1">
                            <div className="aspect-w-4 aspect-h-3">
                                <img src={product.imageUrl} alt={product.title} className="w-full h-64 object-cover" />
                            </div>
                            <div className="p-6">
                                <h4 className="text-lg font-semibold text-gray-900 mb-2">{product.title}</h4>
                                <p className="text-green-600 font-bold">₹{product.price}</p>
                                <a href={product.url} className="mt-4 inline-flex items-center text-sm font-medium text-gray-900">
                                    View Product <ArrowRight className="ml-1 h-4 w-4" />
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Trending Videos */}
            <section className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
                <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">Trending Wellness Videos</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {trendingVideos.map((video) => (
                        <div key={video.id} className="group relative bg-white rounded-2xl shadow-md overflow-hidden transition-all hover:-translate-y-1">
                            <div className="aspect-w-16 aspect-h-9">
                                <img src={video.thumbnail} alt={video.title} className="w-full h-48 object-cover" />
                            </div>
                            <div className="p-6">
                                <h4 className="text-lg font-semibold text-gray-900 mb-2">{video.title}</h4>
                                <a href={video.url} className="inline-flex items-center text-sm font-medium text-gray-900">
                                    Watch Video <ArrowRight className="ml-1 h-4 w-4" />
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default WelcomePage;